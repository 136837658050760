import React from "react"
import PropTypes from "prop-types"
import Icon from "../components/Icon"
import ReactPlayer from "react-player"
import "../style/VideoFullModule.css"
import CtaButton from "../components/CtaButton"
import { GTMEvent } from "../utils/gtm"
import { Box } from "@material-ui/core"
const VideoFullModule = props => {
  const {
    title,
    video_url,
    video_url_mobile,
    cta,
    logo_image,
    repo,
    ecommerce_item_btn_text,
    first_figure_ecommerce_code,
    ecommerce_item_is_active,
  } = props

  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  })

  const refVideo = React.useRef(null)
  const refContainer = React.useRef(null)
  const [isMuted, setIsMuted] = React.useState(true)

  const handleResize = () => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  React.useEffect(() => {
    // Handler to call on window resize
    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= refContainer.current.clientHeight) {
        setIsMuted(true)
      }
    })
  }, [refContainer && refContainer.current])

  const isMobile = windowSize.width <= 960

  return (
    <section className="VideoFullModule" ref={refContainer}>
      <div className="video-full-module-container">
        <ReactPlayer
          ref={refVideo}
          playing={true}
          muted={isMuted}
          controls={false}
          loop={true}
          playsinline
          className="video-full-video"
          width="100%"
          height="100%"
          url={isMobile ? video_url_mobile : video_url}
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                rel: 0,
                controls: 0,
                loop: 1,
                showinfo: 1,
              },
              embedOptions: {
                host: "https://www.youtube-nocookie.com",
              },
            },
          }}
        />
        <div
          className="video-full-icon-sound"
          onClick={() => {
            setIsMuted(!isMuted)
          }}
        >
          {isMuted ? <Icon name="mute" /> : <Icon name="unmute" />}
        </div>
        <div
          className="video-full-scroll-down"
          onClick={() =>
            window.scrollTo({
              top: refContainer.current.clientHeight,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          <Icon name="scroll_down" />
        </div>
        <div className="video-full-content">
          {logo_image && (
            <div className="video-full-logo">
              <img
                src={logo_image.src}
                title={logo_image.title}
                alt={logo_image.alt}
              />
            </div>
          )}
          <div className="video-full-title">{title}</div>
          {ecommerce_item_is_active ? (
            <Box className="double-cta-container">
              <button
                className="add-cart-item-trigger button-add-to-cart new-cta"
                data-sku={first_figure_ecommerce_code}
                disabled
              >
                {ecommerce_item_btn_text}
              </button>
              {(cta?.href || cta?.text) && (
                <CtaButton
                  className="video-full-button"
                  productCode={cta?.productCode}
                  next
                  size="large"
                  {...cta}
                  trackingObj={repo}
                  trackingAction="slider"
                  trackingLabel={"cta-slider"}
                ></CtaButton>
              )}
            </Box>
          ) : (
            (cta?.href || cta?.text) && (
              <CtaButton
                className="video-full-button"
                productCode={cta?.productCode}
                next
                size="large"
                {...cta}
                trackingObj={repo}
                trackingAction="slider"
                trackingLabel={"cta-slider"}
              ></CtaButton>
            )
          )}
        </div>
      </div>
    </section>
  )
}

VideoFullModule.propTypes = {
  title: PropTypes.string,
  video_url: PropTypes.string,
  video_url_mobile: PropTypes.string,
  cta: {
    highlightedText: PropTypes.string,
    text: PropTypes.string,
  },
  logo_image: PropTypes.object,
}

export default VideoFullModule
