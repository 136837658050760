import React from "react"
import { Container, Box, Hidden, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import clsx from "clsx"
import Text from "../components/Text"
import Video from "../components/Video"
import Module from "../components/Module"

const useStyles = makeStyles(theme => ({
  videoBanner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    color: "white",
    overflow: "hidden",
    height: "700px",
    //marginTop: theme.spacing(1 / 2), // FIX ** better to no margin inside modules if not necessary
    //marginBottom: theme.spacing(1 / 2),
    [theme.breakpoints.down("sm")]: {
      color: "black",
      height: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      top: "32px",
    },
  },
  container: {
    width: "100%",
    height: "100%",
  },
  title: {
    //marginTop: theme.spacing(7), // FIX ** better to no margin inside modules if not necessary
    //marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      backgroundColor: "white",
      //...theme.fonts.faces.specialTitleXL
    },
  },
  videoWrapper: {
    //position: 'relative',
    //height: 'min(max(45vw, 235px), 636px)'
    height: 510,
    width: "87%",
    margin: "0 auto",
    boxShadow: theme.shadows[16],
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      //marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(3.5),
    },
    [theme.breakpoints.down("xs")]: {
      height: 200,
      width: "100%",
      //marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(3.5),
    },
  },
  playerWrapper: {
    height: "100%",
  },
  background: {
    position: "absolute",
    top: "-10%",
    left: "-10%",
    backgroundSize: "150% 150%",
    backgroundPosition: "center 90%",
    opacity: 0.8,
    zIndex: -2,
    width: "120%",
    height: "120%",
    filter: "blur(20px)",
    WebkitMaskImage:
      "-webkit-linear-gradient(rgba(0,0,0,1) 35%, rgba(0,0,0,0) 70%)",
    maskImage: "linear-gradient(rgba(0,0,0,1) 30%, rgba(0,0,0,0) 70%)",
    [theme.breakpoints.down("sm")]: {
      top: "0%",
    },
  },
}))

const VideoBannerModule = ({ title, video, ...VideoBannerModule }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const image = video && video.preview ? video.preview.src : ""

  return (
    <Module
      className={classes.videoBanner}
      classes={{ container: classes.container }}
    >
      <Parallax
        className={clsx(classes.background, "lazyload")}
        bgImage={{ src: image }}
        parallaxStyle={{ width: "120%", position: "absolute" }}
      ></Parallax>
      <Hidden smUp>
        <div
          style={{
            background: "white",
            position: "absolute",
            zIndex: -1,
            top: 0,
            height: "50px",
            width: "100%",
          }}
        ></div>
      </Hidden>
      <ParallaxItem disabled={isMobile} y={["2", "-2"]}>
        <Text
          className={classes.title}
          variant="titleXXXL"
          weight="bold"
          align="center"
          color={!image ? "textPrimary" : "initial"}
          overline
        >
          {title}
        </Text>
        <Box className={classes.videoWrapper}>
          <Video
            fullscreen={false}
            className={classes.video}
            classes={{ playerWrapper: classes.playerWrapper }}
            showPlayButtonText
            {...video}
            tracking={VideoBannerModule.repo || null}
          />
        </Box>
      </ParallaxItem>
    </Module>
  )
}

export default VideoBannerModule
